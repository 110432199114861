import React from 'react';

export default () => (
  <footer className='container-fluid black_more'>
  <div className='bg-footer'>
    <img src='./img/footer-img.png' alt='bg-footer'/>
  </div>
    <div className='row m-2-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            <h2>Textil surtimoda</h2>
          </div>
          <div className='content'>
             <p>Nuestra mision es lograr la satisfacción plena de los clientes, 
             brindándoles el mejor servicio y confianza, recortando las distancias en la innovación textil.</p>
          </div>
          <div className='link-call' onClick={()=> window.open("mailto:tsurtimoda@gmail.com", "_self")}>tsurtimoda@gmail.com</div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            
          </div>
          <div className='content'>
             <div className='link'></div>
             <div className='link'></div>
             <div className='link'></div>
             <div className='link'></div>
          </div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            
          </div>
          <div className='content'>
            <div className='link'></div>
            <div className='link'></div>
            <div className='link'></div>
            <div className='link'></div>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            Siguenos
          </div>
          <div className='content'>
            <p>Explora, conoce nuevas tendencias, inspirate con nuestras en telas y mantente a la vanguardia en moda.</p>
            <a href="https://www.facebook.com/textilsurtimoda?fref=ts" target="_blank"><div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-facebook-f"></i>
            </div></a>
            <a href="https://twitter.com/Textilsurtimoda?s=08" target="_blank"><div className='socialicon'>
            <span className="shine"></span>
              <i className="fa fa-twitter"></i>
            </div></a>
            <a href="https://www.instagram.com/textilsurtimoda/" target="_blank"><div className='socialicon'>
            <span className="shine"></span>
              <i className="fa  fa-instagram"></i>
            </div></a>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='row m-2-hor'>
        <div className='col-md-6'>
          <div className='content'>Hecho con amor 2021.</div>
        </div>
      </div>
    </div>
  </footer>
);
