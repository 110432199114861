import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./styles/animated-on3step.css";
import "./styles/main.scss";

import Preloader from "./components/Preloader";
import NavigationMenu from "./components/Nav";
import ScrollToTopBtn from './components/ScrollToTop';
import Home from "./pages/Home";
import About from "./pages/About";
import Work from "./pages/works";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Case from "./pages/detailCase";
import Case1 from "./pages/detailCase1";
import Case2 from "./pages/detailCase2";
import Case3 from "./pages/detailCase3";
import Case4 from "./pages/detailCase4";
import Case5 from "./pages/detailCase5";
import Case6 from "./pages/detailCase6";
import Case7 from "./pages/detailCase7";
import Case8 from "./pages/detailCase8";
import Case9 from "./pages/detailCase9";
import Case10 from "./pages/detailCase10";
import Case11 from "./pages/detailCase11";
import Case12 from "./pages/detailCase12";
import Case13 from "./pages/detailCase13";
import Case14 from "./pages/detailCase14";
import Case15 from "./pages/detailCase15";
import Case16 from "./pages/detailCase16";
import Case17 from "./pages/detailCase17";
import Newsdetail from "./pages/detailNews";


function App() {
  const [loading, setLoading] = useState(true);
  const [navBackground, setNavBack] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [loading]);

  return (
    <Router>
      {loading ? (
        <Preloader />
      ) : (
        <div className="App">
          <NavigationMenu
            firstTime={loading}
            hasBackground={navBackground}
            setBackground={state => setNavBack(state)}
          />
          <Route path="/" exact component={Home} />
          <Route path="/About" exact component={About} />
          <Route path="/Work" exact component={Work} />
          <Route path="/News" exact component={News} />
          <Route path="/Contact" exact component={Contact} />
          <Route
            path="/detailcase"
            component={() => (
              <Case setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase1"
            component={() => (
              <Case1 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase2"
            component={() => (
              <Case2 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase3"
            component={() => (
              <Case3 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase4"
            component={() => (
              <Case4 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase5"
            component={() => (
              <Case5 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase6"
            component={() => (
              <Case6 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase7"
            component={() => (
              <Case7 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase8"
            component={() => (
              <Case8 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase9"
            component={() => (
              <Case9 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase10"
            component={() => (
              <Case10 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase11"
            component={() => (
              <Case11 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase12"
            component={() => (
              <Case12 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase13"
            component={() => (
              <Case13 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase14"
            component={() => (
              <Case14 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase15"
            component={() => (
              <Case15 setNavBackground={state => setNavBack(state)} />
            )}
          />
          <Route
            path="/detailcase16"
            component={() => (
              <Case16 setNavBackground={state => setNavBack(state)} />
            )}
          /><Route
            path="/detailcase17"
            component={() => (
              <Case17 setNavBackground={state => setNavBack(state)} />
            )}
          />
          
          <Route
            path="/detailNews"
            component={() => (
              <Newsdetail setNavBackground={state => setNavBack(state)} />
            )}
          />
          
        <ScrollToTopBtn />  
        </div>
      )}
    </Router>
  );
}

export default App;