import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Accomplish from '../components/Accomplish';
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/work");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {

    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "url('./img/projects/big/7.jpg')" }}>
              <div className='herocaption'>
                <BackButton className='backdetail'
                  onClick={() => this.setState({ toBack: true, hasBackground: false })}
                  toBack={this.state.toBack}
                  hasBackground={this.state.hasBackground}>
                  <BackArrow src="./img/back.png" alt="Back to Projects" />
                  <span>Back </span>
                </BackButton>
                <ClientTag>Primavera Verano</ClientTag>
                <Title>LINEN 6032</Title>
                <TechniqueTitle>
                  </TechniqueTitle>
              </div>
          </Hero>
          </Reveal>

        <section className='container-fluid' id='detailproject'>
          <div className='row m-2-hor'>
            <div className='col-md-8'>
              <p className='content'>Textil que recuerda lo natural y clásico, 
reinterpretado para estar en vanguardia sin perder su esencia.</p>
              
            </div>
            <div className='col-md-4 sticky'>
              <ul className='detailproject'>
                <li>
                  <span className='tile'>Colores:</span>
                  <span>Lt. sage, Lt rose, ivory, yellow, natural</span>
                </li>
                <li>
                  <span className='tile'>Metraje de rollos:</span>
                  <span>entre 40 y 90 metros</span>
                </li>
                <li>
                  <span className='tile'>Composicion:</span>
                  <span>55% linen 45% rayon</span>
                </li>
                <li>
                  <span className='tile'>Ancho:</span>
                  <span>1.35 / 1.37 metros</span>
                </li>
              </ul>
            </div>
            <div className='col-md-12'>
             <div className='tags'>
              <span className='heading'>
               Tags :
              </span>
              <span className='content'>     
                natural
              </span>
              <span className='content'>     
                fresco
              </span>
              <span className='content'>     
                atractivo
              </span>
              
             </div>
            </div>
            <div className='col-md-12'>
             <div className='share'>
              <span className='heading'>
               Síguenos:
              </span>
               <a href="https://www.facebook.com/textilsurtimoda?fref=ts" target="_blank">
                <span className='content'>     
                 <i className="fa fa-facebook-f"></i>
                </span>
               </a>
               <a href="https://twitter.com/Textilsurtimoda?s=08" target="_blank">
                <span className='content'>     
                <i className="fa fa-twitter"></i>
                </span>
               </a>
               <a href="https://www.instagram.com/textilsurtimoda/" target="_blank">
                <span className='content'>     
                <i className="fa  fa-instagram"></i>
               </span>
              </a>
             </div>
            </div>
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>


      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Case);
