import React from 'react';

export default () => (
  <section className='container-fluid'>
          <div className='row m-2-hor'>
            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/home.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>¿QUIENES SOMOS?</div>
                  <div className='content'>
                    Textil Surtimoda surge de la creciente preocupación por las nuevas
                    tendencias de la moda internacional enfocada a la cultura   
                    y preferencias mexicanas. 
                    Desde 1995, ofreciendo bienestar y satisfacción económica a las familias.
                  </div>
                  <ul className='list-home'>
                    <li>Calidad</li>
                    <li>Compromiso</li>
                    <li>Confianza </li>
                    <li>Honestidad</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </section>
);
