import React from "react";
import { withRouter } from "react-router-dom";
import emailjs from 'emailjs-com';
import Reveal from 'react-reveal/Reveal';

import SimpleMap from '../components/Map';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Contact = ({ history }) => {

    function sendEmail(e) {
    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_csfdEZiA', e.target, 'user_zu7p2b3lDibMCDutH5hif')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }  

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>

      <Reveal effect="fadeInUp">
       <section className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1>Contáctanos y comienza a diseñar con <br/>tu <span className='color'>Tela</span> soñada</h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
       <section className='container pt-0'>
          <div className='row'>
            <div className='col-12'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14936.980220894695!2d-103.0658143!3d20.6188659!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x167c396c733c66d7!2sTextil%20Surtimoda!5e0!3m2!1ses-419!2smx!4v1619124974638!5m2!1ses-419!2smx" width="100%" height="600" style={{"border":"0"}} allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div className='col-md-6'>
            <div className="text-side">
              <h3 className="heading">Habla con un experto textil</h3>
              <p>Nuestra visión es ser la empresa textil líder de México, basándonos en los mejores 
              estándares de calidad y con una organización eficiente y moderna.</p>
              <div className='address'>
                <div className='heading'>Nuestra oficina</div>
                <div className='list'>
                  <i className="fa fa-map-marker"></i>
                  Aquiles Serdán No. 86-A Zapotlanejo, Jal. C.P. 45430
                </div>
                <div className='list'>
                  <i className="fa fa-envelope-o"></i>
                  <a href='mailto:tsurtimoda@gmail.com' target='_blank' rel='noopener noreferrer'>
                    tsurtimoda@gmail.com
                  </a>
                </div>
                <div className='list'>
                  <i className="fa fa-phone"></i>
                  Tel. / Fax:  01 (373) 734 21 74
                </div>
                </div>
              </div>
            </div>




          </div>
       </section>
      </Reveal>

      

      <Footer />

    </div>
    
  );
};

export default withRouter(Contact);
