import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';

import {
  LinkWrap,
  Overlay
} from "../styles/Work.styles";

import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const Works = ({ history }) => {
const [toCase, setCase] = useState("");
const [coord, setCoords] = useState();

useEffect(() => {
    toCase &&
      setTimeout(() => {
        history.push(toCase);
      }, 600);
  }, [toCase, history]);


  const handleCaseSwap = (e, uri) =>
    e.x < coord + 15 && e.x > coord - 15 && setCase(uri);

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>

      <Reveal effect="fadeInUp">
       <section className='container-fluid pb-0'>
          <div className='row m-2-hor'>
            <div className='col-md-12'>
              <h1 className="heading mt-5">Conoce y encuentra la tela indicada de tus prendas
               diseñadas para primavera verano</h1>
            </div>
          </div>
       </section>
      </Reveal>

       <Reveal effect="fadeInUp">
       <section className='container-fluid'>
          <div className='row m-2-hor'>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase"}>                                                          
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>TOSCANA</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase1"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase1")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img2.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>SHIFON METALLIC</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase2"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase2")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img3.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>URAGIRI LUREX</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase3"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase3")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img4.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>BOLLERO BRUSH</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase4"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase4")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img5.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LINEN SLUB</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase5"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase5")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/img6.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LICRA ALGODON JERSEY</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase6"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase6")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/9.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>JOUGA LISA</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase7"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase7")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/16.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LESLY BRUSH RIB</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase8"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase8")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/4.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LESLY BRUSH RIB ESTAMPADA 1254</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase9"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase9")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/17.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LESLY BRUSH RIB</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase10"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase10")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/18.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LESLY BRUSH RIB</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase11"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase11")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/19.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LESLY BRUSH RIB</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase12"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase12")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/20.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>SHIFON METALIC 9742</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase13"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase13")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/15.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>SIENNA LUREX</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase14"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase14")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/6.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LINEN 6032</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase15"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase15")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/7.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LINEN 6032</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase16"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase16")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/3.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>LESLY BRUSH RIB ESTAMPADA 1253</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 slick slickproject p-3'>
              <div className='slick-slide d-block'>
                <div>
                  <div className='itm'>
                  <LinkWrap active={toCase === "/detailcase17"}>
                    <Overlay active={!!toCase} onMouseDown={e => setCoords(e.nativeEvent.x)}
                      onMouseUp={e => handleCaseSwap(e.nativeEvent, "/detailcase17")}>
                      <div className='bg'>
                        <img
                          src="./img/projects/8.1.jpg"
                          className="img-fluid"
                          alt="Imageworks"
                        />
                      </div>
                      <div className='desc'>
                        <div className='tag'>Primavera Verano</div>
                        <div className='name'>MADRID LISA</div>
                      </div>
                      <div className='icon'>
                         <span>
                          Ver más
                        </span>
                      </div>
                    </Overlay>
                  </LinkWrap>
                  </div>
                </div>
              </div>
            </div>

      

          </div>
       </section>
       </Reveal>

      <Footer />

    </div>
    
  );
};

export default withRouter(Works);
